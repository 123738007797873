<template>
  <!-- <div
    class="social-account-box"
    :class="{ active: selected, disabled: disabled || !publishAccount.type.isPublishable }"
    @click="handleToggle"
  >
    <b-avatar class="avatar" size="4.5rem" />

    <div class="account-name">{{ publishAccount.name }}</div>
    <div class="account-type">{{ publishAccount.type.displayName }}</div>
  </div> -->
  <div class="account-icons">
    <div
      class="account-container custom-shadow social-account-box"
      :class="{ active: selected, disabled: disabled || !publishAccount.type.isPublishable }"
      @click="handleToggle"
    >
      <div class="account-box">
        <div>
          <div class="size-10 overflow-hidden rounded-1/2">
            <img class="w-100 h-100 object-cover" :src="publishAccount.imageUrl" @error="handleImageError" />
          </div>
        </div>
      </div>
      <span class="trunc alphabet-icon" style="font-size: 14px; text-align: center;">{{ publishAccount.name }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    publishAccount: {
      type: Object,
      required: false,
    },

    selected: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    handleToggle() {
      this.$emit('toggle');
    },
    handleImageError(e) {
      e.target.src = '/assets/images/users/2.jpg'; // Replace with your default image path
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.account-container {
  width: 150px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ececec;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.account-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: #c4c4c4;
  border-radius: 50%;
  margin: 0.5rem;
  cursor: pointer;
}

.account-container:hover {
  transform: scale(1.1);
  background-color: #f8f9fa;
}

.account-icons {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.plus-icon {
  font-size: 24px;
  color: black;
}

.alphabet-icon {
  font-size: 16px;
  font-weight: bold;
  color: #343a40;
  &.trunc {
    overflow: hidden;
    max-width: 75px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.social-account-box {
  margin-right: 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid $gray-200;
  border-radius: 0.5rem;
  width: 100px;
  height: 100px;
  position: relative;
  user-select: none;
  box-shadow: 0 1px 5px $gray-200;
  color: $primary;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .social-account-box-img {
    padding: 3px;
  }

  .account-name {
    margin-top: 10px;
    font-weight: bold;
    font-size: 12px;
    max-width: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .account-type {
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 3px;
    font-weight: lighter;
    font-size: 14px;
  }
}

.social-account-box.active {
  cursor: pointer;
  border-color: $primary;
  border-width: 2px;
}
</style>
