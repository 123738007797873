<template>
  <div class="social-account-chooser ">
    <!-- <div class="mb-2 mx-auto text-center">Please select one or more social account(s) below</div> -->
    <!-- <AddPublishAccount v-if="publishAccounts.length && !fetching" :queryType="queryType" tag="box" /> -->
    <publish-accounts-skeleton :itemCount="4" v-if="fetching" />
    <div v-if="publishAccounts.length && !fetching">
      <div class="accounts-wrap">
        <SocialAccountSelectBox
          v-for="publishAccount in publishAccounts"
          :key="publishAccount.id"
          :selected="selectedAccounts.includes(publishAccount)"
          :disabled="disabled"
          :publishAccount="publishAccount"
          @toggle="toggleAccountSelection(publishAccount)"
        />
      </div>
    </div>
    <div v-if="!publishAccounts.length && !fetching" class=" text-center p-5">
      <img class="my-2" width="250" src="@/assets/images/account.svg" alt="" />
      <h6 class="my-4">You have not connected any social account yet.</h6>
      <AddPublishAccount :queryType="queryType" class="px-4" size="sm">
        <svg-icon name="plus" class="mr-2" />
        Add Account
      </AddPublishAccount>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { PUBLISH_ACCOUNTS_QUERY } from '~/graphql/queries';
import AddPublishAccount from '~/components/AddPublishAccount';
import PublishAccountsSkeleton from '~/components/PublishAccountsSkeleton';
import SocialAccountSelectBox from '~/components/SocialAccountSelectBox';

export default {
  components: { PublishAccountsSkeleton, AddPublishAccount, SocialAccountSelectBox },

  props: {
    // selectedAccounts: {
    //   type: Array,
    //   default: () => [],
    // },

    accounts: {
      type: Array,
      default: () => [],
    },

    queryType: {
      type: String,
      default: 'social',
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      fetching: false,
      publishAccounts: [],
    };
  },

  computed: {
    ...mapState({
      workspace: state => state.workspace.current,
      ...mapState('publishAccount', ['platformTypeToAdd']),
    }),
    ...mapState('createAutomation', {
      selectedAccounts: state => state.selectedAccounts,
    }),
  },

  watch: {
    accounts(accounts) {
      this.publishAccounts = accounts;
    },
    platformTypeToAdd(newType) {
      this.fetchAccounts(newType);
    },
  },

  created() {
    // if (this.accounts.length < 1) {
    //   this.fetching = true;

    //   this.$apollo
    //     .query({
    //       query: PUBLISH_ACCOUNTS_QUERY,
    //       variables: {
    //         workspace: this.workspace.id,
    //         type: 'social',
    //       },
    //     })
    //     .then(({ data }) => {
    //       this.publishAccounts = data.publishAccounts;

    //       this.$store.dispatch('publishAccount/updateAccounts', data.publishAccounts);

    //       this.fetching = false;
    //     })
    //     .catch(() => {
    //       this.fetching = false;
    //     });
    // } else {
    //   this.publishAccounts = this.accounts;
    // }
    this.fetchAccounts(this.platformTypeToAdd);
    console.log(this.publishAccounts);
  },

  methods: {
    ...mapActions('publishAccount', ['updateAccounts']),
    fetchAccounts(platformType) {
      this.fetching = true;
      this.$apollo
        .query({
          query: PUBLISH_ACCOUNTS_QUERY,
          variables: {
            workspace: this.workspace.id,
            type: platformType,
          },
        })
        .then(({ data }) => {
          this.publishAccounts = data.publishAccounts;
          console.log(this.publishAccounts);

          this.$store.dispatch('publishAccount/updateAccounts', data.publishAccounts);
          this.fetching = false;
        })
        .catch(error => {
          console.error('Error fetching accounts:', error);
          this.fetching = false;
        });
    },

    /**
     * Adds or removes a given account from the selected account ids array
     * @param  {Int} accountId A social account ID
     * @return {void}
     */
    toggleAccountSelection(account) {
      const index = this.selectedAccounts.findIndex(acc => acc.id === account.id);
      if (index === -1) {
        this.selectedAccounts.push(account);
      } else {
        this.selectedAccounts.splice(index, 1);
      }
      // this.$store.dispatch('createAutomation/updateSelectedAccounts', update);

      console.log('Selected Accounts:', this.selectedAccounts);

      this.$emit('sync', {
        account,
        platform: account.type.platform,
        publishVariant: 'social',
      });
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.social-account-chooser {
  .skeleton-wrap {
    .social-account-skeleton {
      width: 160px;
      height: 150px;
      margin-right: 15px;
      margin-bottom: 15px;
    }
  }

  .accounts-wrap {
    display: flex;
    flex-wrap: wrap;
    // margin-right: -15px;
    gap: 1rem;
  }
}
</style>
